export default {
  mandrill: { fore: "#7dc969", back: "#853999" },
  otter: { fore: "#e7a860", back: "#1e5aa2" },
  shark: { fore: "#018bcc", back: "#7b0000" },
  quail: { fore: "#3a1d00", back: "#3b63e2" },
  chough: { fore: "#96df39", back: "#6d24c9" },
  elk: { fore: "#31213a", back: "#617658" },
  dolphin: { fore: "#f483bc", back: "#00652f" },
  parrot: { fore: "#40004a", back: "#1e7c1a" },
  tarsier: { fore: "#7c632a", back: "#001944" },
  coyote: { fore: "#d5d6f0", back: "#27260c" },
  owl: { fore: "#ffec60", back: "#0d16a2" },
  dunlin: { fore: "#5e000d", back: "#168085" },
  frog: { fore: "#41e1c8", back: "#b91b34" },
  alpaca: { fore: "#bcf7be", back: "#40053e" },
  "water-buffalo": { fore: "#03eac7", back: "#e9002c" },
  manatee: { fore: "#9ac1aa", back: "#623b52" },
  hornet: { fore: "#57fdd1", back: "#ac0b31" },
  worm: { fore: "#c4efb4", back: "#380d48" },
  cat: { fore: "#5f95cd", back: "#683b01" },
  lark: { fore: "#def3e0", back: "#230f21" },
  weasel: { fore: "#edc2d9", back: "#0f3a23" },
  ostrich: { fore: "#01cf85", back: "#c2004d" },
  "elephant-seal": { fore: "#17e8f9", back: "#e40e02" },
  hummingbird: { fore: "#f88959", back: "#005c8a" },
  pig: { fore: "#e0fc36", back: "#2708cc" },
  giraffe: { fore: "#3be82d", back: "#bf14ce" },
  seahorse: { fore: "#dae108", back: "#2d22fb" },
  marten: { fore: "#de343f", back: "#003531" },
  eel: { fore: "#7e594f", back: "#00171e" },
  snail: { fore: "#83f1af", back: "#7f1353" },
  chimpanzee: { fore: "#58e426", back: "#a318d4" },
  snake: { fore: "#27e082", back: "#d01077" },
  pigeon: { fore: "#d5c505", back: "#313dfe" },
  camel: { fore: "#3c7527", back: "#34024a" },
  quelea: { fore: "#929906", back: "#1b33bc" },
  toad: { fore: "#48cdf5", back: "#bb350e" },
  deer: { fore: "#d386d1", back: "#096017" },
  louse: { fore: "#516809", back: "#00014c" },
  kookabura: { fore: "#db986b", back: "#0f5b87" },
  mosquito: { fore: "#67cbc5", back: "#9b373d" },
  grasshopper: { fore: "#f78f5e", back: "#005e8e" },
  mule: { fore: "#46b85c", back: "#931f80" },
  capybara: { fore: "#9dbd49", back: "#5f3fb3" },
  meerkat: { fore: "#f2cbd9", back: "#103729" },
  human: { fore: "#f85f6b", back: "#005149" },
  heron: { fore: "#daab80", back: "#295782" },
  newt: { fore: "#e51f17", back: "#00313c" },
  gull: { fore: "#4fedb9", back: "#ac0f43" },
  okapi: { fore: "#d5c796", back: "#2d3b6c" },
  skunk: { fore: "#d7cd00", back: "#242ffc" },
  cattle: { fore: "#b5d6d0", back: "#47262c" },
  "giant-panda": { fore: "#b6a44c", back: "#394ea4" },
  tapir: { fore: "#189506", back: "#610078" },
  hyena: { fore: "#321400", back: "#3c61c7" },
  clam: { fore: "#82dbbe", back: "#7a213e" },
  "sea-lion": { fore: "#a09628", back: "#fffdff" },
  badger: { fore: "#1d2800", back: "#6f4ef4" },
  sheep: { fore: "#f34153", back: "#004439" },
  cod: { fore: "#90e75f", back: "#6c159d" },
  alligator: { fore: "#2f1b42", back: "#5b7546" },
  crab: { fore: "#fa9b3a", back: "#005cbc" },
  salamander: { fore: "#c7fc85", back: "#350077" },
  gnu: { fore: "#87ba97", back: "#7b486b" },
  dugong: { fore: "#6aa7f9", back: "#814700" },
  yak: { fore: "#cc5bd5", back: "#004b00" },
  flamingo: { fore: "#002e1d", back: "#d62744" },
  emu: { fore: "#c3b7ec", back: "#394510" },
  lobster: { fore: "#5fe435", back: "#a320cd" },
  wolverine: { fore: "#52ceba", back: "#b13448" },
  "guinea-pig": { fore: "#281627", back: "#566d58" },
  rat: { fore: "#c27498", back: "#004c2d" },
  hawk: { fore: "#b8afac", back: "#444d50" },
  raccoon: { fore: "#d5bda6", back: "#273f56" },
  leopard: { fore: "#003400", back: "#c54187" },
  bison: { fore: "#ada6bd", back: "#4f563f" },
  rabbit: { fore: "#65f930", back: "#9e10cf" },
  seal: { fore: "#b3c689", back: "#4f3c79" },
  butterfly: { fore: "#f63cc7", back: "#004f00" },
  ibis: { fore: "#9bb8bf", back: "#674a43" },
  jellyfish: { fore: "#50f791", back: "#b31171" },
  dove: { fore: "#87aec6", back: "#754e36" },
  grouse: { fore: "#975feb", back: "#063e00" },
  wren: { fore: "#1ec082", back: "#af0055" },
  wasp: { fore: "#91f35d", back: "#6b099f" },
  hamster: { fore: "#d9ad80", back: "#2a5582" },
  walrus: { fore: "#002f12", back: "#975e84" },
  dogfish: { fore: "#a1bc6c", back: "#614696" },
  elephant: { fore: "#c8b689", back: "#344673" },
  panther: { fore: "#3eb291", back: "#96244b" },
  chamois: { fore: "#bff91c", back: "#450de7" },
  lyrebird: { fore: "#dd3c4d", back: "#00382f" },
  dinosaur: { fore: "#ac9083", back: "#324d5a" },
  scorpion: { fore: "#10d06e", back: "#bf0069" },
  gnat: { fore: "#7dabbd", back: "#764a38" },
  gerbil: { fore: "#ccd11d", back: "#3931e5" },
  caribou: { fore: "#3a1900", back: "#3967c9" },
  oyster: { fore: "#9fcdb9", back: "#633549" },
  anteater: { fore: "#98c613", back: "#6436e6" },
  goose: { fore: "#c5af1f", back: "#314bda" },
  cockroach: { fore: "#f03a4a", back: "#004037" },
  woodpecker: { fore: "#fbc28e", back: "#003a6e" },
  crane: { fore: "#e8d65e", back: "#13269e" },
  albatross: { fore: "#f0edcc", back: "#121436" },
  kudu: { fore: "#002b00", back: "#b1448a" },
  eland: { fore: "#5e7367", back: "#31202a" },
  bee: { fore: "#eae8f8", back: "#17190b" },
  lemur: { fore: "#c1bcd4", back: "#41462e" },
  wombat: { fore: "#7fa56d", back: "#633f75" },
  goldfinch: { fore: "#3fa6a7", back: "#89282d" },
  moose: { fore: "#75ede0", back: "#860f1c" },
  ape: { fore: "#36a8a6", back: "#8f222d" },
  wallaby: { fore: "#b0c04f", back: "#4c3cad" },
  cheetah: { fore: "#002200", back: "#bb3256" },
  koala: { fore: "#c6be97", back: "#3c446b" },
  baboon: { fore: "#33c8bd", back: "#b92335" },
  ibex: { fore: "#331a2c", back: "#53735d" },
  finch: { fore: "#ca530b", back: "#002d66" },
  eagle: { fore: "#9f5e1a", back: "#002256" },
  vicuna: { fore: "#eadb83", back: "#1a277f" },
  aardvark: { fore: "#c5cc2c", back: "#3630cf" },
  "sea-urchin": { fore: "#dde7c9", back: "#251a39" },
  dotterel: { fore: "#86c727", back: "#7635d4" },
  fox: { fore: "#95e807", back: "#6614ed" },
  gorilla: { fore: "#f8f713", back: "#180ef0" }
}
