/** @jsx jsx */
/* @jsxFrag React.Fragment */
// eslint-disable-next-line
import React from "react"
import { css, jsx, withThemeAndData } from "../view"
import "../icons"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"

const HomeButton = ({ RoutedLink, theme }) => (
  <RoutedLink
    href="/"
    css={css({
      color: theme.colors.fore,
      textDecoration: "none",
      marginTop: "1rem",
      marginBottom: "1rem"
    })}
  >
    <Icon icon="home" /> &#8619;
  </RoutedLink>
)

export default withThemeAndData(HomeButton)
