/** @jsx jsx */
/* @jsxFrag React.Fragment */
// eslint-disable-next-line
import React from "react"
import { withThemeAndData, jsx, Button } from "../view"
import styles from "./styles"

const NextColorButton = ({ bem, nextColor, palette, override, theme }) => (
  <Button
    title="Next Random Color"
    className={bem("button", "regenerate")}
    css={
      override ? [styles.button({ theme }), override] : styles.button({ theme })
    }
    onClick={nextColor}
  >
    &rarr;
  </Button>
)

export default withThemeAndData(NextColorButton)
