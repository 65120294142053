/** @jsx jsx */
/* @jsxFrag React.Fragment */
// eslint-disable-next-line
import React from "react"
import blem from "blem"
import { curry } from "ramda"
import { Text, Box, css, jsx, withThemeAndData } from "./view"
import { fore, back } from "./styles"
import { ReactComponent as Logo } from "./logos/brekk-is-lost.svg"
import ControlPanel from "./ControlPanel"
import HomeButton from "./buttons/Home"

const slurpLocation = e => {
  const f = e.toString()
  const raw = f.toString().substr(f.indexOf("/"))
  return '"' + raw.replace(/\//g, "").replace(/-/g, " ") + '"'
}

export const RoutingError = props => {
  const bem = blem("App")
  return (
    <Box
      className="Error Error--404"
      css={css({
        backgroundColor: back(props),
        color: fore(props),
        width: "100vw",
        height: "100vh",
        textAlign: "center",
        padding: "10% 4rem",
        margin: 0
      })}
    >
      <Logo css={css({ fill: fore(props), marginBottom: "1rem" })} />
      <Text css={css({ marginBottom: "1rem" })}>
        Brekk is doing lots of stuff, but apparently not{" "}
        {slurpLocation(props.error)}.
      </Text>
      <ControlPanel bem={bem} />
      <HomeButton bem={bem} />
    </Box>
  )
}
// currently this export is just for storybook porpoises
export const StyledError = withThemeAndData(RoutingError)

const error = curry((theme, error) => <RoutingError {...{ theme, error }} />)

export default error
