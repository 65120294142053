import { pipe } from "ramda"
import { defaultProps } from "recompose"
import blemify from "blem"
import { withTheme as hocTheme } from "emotion-theming"
import { withData as hocData, DataContext as context } from "./data"
import { NavLink } from "react-navi"
export { Flex, Box } from "@rebass/grid/emotion"
export { Button, Link, Image, Card, Text, Heading } from "@rebass/emotion"
export { jsx, css } from "@emotion/core"
export const blem = blemify
export { zstyle, stylify } from "./styles"
export const withTheme = hocTheme
export const withData = hocData
export const withThemeAndData = pipe(
  withData,
  withTheme,
  defaultProps({
    RoutedLink: NavLink
  })
)
export const DataContext = context
