import { stylify, fore, back } from "../styles"

const styles = stylify({
  button: p => ({
    transition:
      "background-color 0.3s ease-out, color 0.3s ease-out, border 0.3s ease-out",
    color: back(p),
    backgroundColor: fore(p),
    border: `1px solid ${back(p)}`,
    outline: 0,
    cursor: `pointer`,
    margin: `0.5rem`,
    fontSize: `1rem`,
    lineHeight: `1rem`,
    width: `2rem`,
    height: `2rem`,
    borderRadius: `1rem`,
    textAlign: `center`,
    padding: 0,
    "&:hover": {
      backgroundColor: back(p),
      color: fore(p),
      border: `1px solid ${fore(p)}`
    }
  }),
  buttonPrev: ({ theme, previousSwatch }) => {
    return `
    &::before {
      content: '${(previousSwatch && previousSwatch.length) || ``}';
      position: absolute;
      margin-left: -2rem;
      color: ${fore({ theme })};
    }
  `
  }
})

export default styles
