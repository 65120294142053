import { createPage, createSwitch } from "navi"
// eslint-disable-next-line
import React from "react"
import { map, pipe, reduce, merge } from "ramda"
import { PACKAGES } from "./constants"
import caseify from "pascal-case"

const packageRoutes = pipe(
  map(p => {
    const toImport = caseify(p)
    return {
      [`/writing/package/${p}`]: {
        title: `brekk.is writing software - ${p}`,
        getContent: () => import(`./packages/${toImport}`)
      }
    }
  }),
  reduce(merge, {})
)(PACKAGES)

export default createSwitch({
  paths: pipe(
    merge(packageRoutes),
    map(createPage)
  )({
    "/": {
      title: `brekk.is`,
      getContent: () => import("./Home")
    }
  })
})
