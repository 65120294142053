import {
  faStop,
  faExternalLinkSquareAlt,
  faPlay,
  faHome,
  faBolt,
  faCalendarTimes,
  faWrench,
  faCode,
  faFile,
  faCircle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons"
import {
  faCodepen,
  faDribbble,
  faGithub,
  faGitlab,
  faTwitter
} from "@fortawesome/free-brands-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core"

library.add(
  faExternalLinkSquareAlt,
  faStop,
  faPlay,
  faHome,
  faCircle,
  faCheckCircle,
  faFile,
  faBolt,
  faCalendarTimes,
  faWrench,
  faCode,
  faCodepen,
  faDribbble,
  faGithub,
  faGitlab,
  faTwitter
)
