/** @jsx jsx */
/* @jsxFrag React.Fragment */
// eslint-disable-next-line
import React from "react"
import { withThemeAndData, jsx, Button } from "../view"
import styles from "./styles"

const PreviousColorButton = ({
  previousSwatch = [],
  bem,
  previousColor,
  override,
  theme
}) =>
  previousSwatch.length > 0 ? (
    <Button
      title="Previous Color"
      className={bem("button", "go-back")}
      css={
        override
          ? [
              styles.button({ theme }),
              styles.buttonPrev({ theme, previousSwatch }),
              override
            ]
          : [
              styles.button({ theme }),
              styles.buttonPrev({ theme, previousSwatch })
            ]
      }
      onClick={previousColor}
    >
      &larr;
    </Button>
  ) : null

export default withThemeAndData(PreviousColorButton)
