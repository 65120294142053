import { pathOr, merge, curry, map, pipe } from "ramda"
import { flip, random } from "f-utility"
import mixColor from "mix-color"
import bestContrast from "get-best-contrast-color"
import { css } from "@emotion/core"

import colors from "./colors"
import { getSearch } from "./utils"
import memo from "memoizee"
const { entries } = Object
const { isArray } = Array
const isFunction = x => typeof x === `function`

export const mix = memo(mixColor)

export const namedColorKV = memo(([name, { fore, back }]) => ({
  name,
  fore,
  back
}))

export const readableColor = flip(memo(bestContrast))
export const blackOrWhite = memo(readableColor(["#fff", "#000"]))

export const stylify = map(x => (isFunction(x) ? pipe(x, css) : css(x)))
export const zstyle = curry((z, bem, x) => {
  const isR = isArray(x)
  const ref = isR ? x[0] : x
  const className = isR ? bem(x[0], x[1]) : bem(x)
  if (!z[ref])
    console.log(`!`, ref, className, x, `has no definition in styles!`)
  return {
    css: z[ref],
    className
  }
})
export const randomColor = () =>
  pipe(entries, random.pick, namedColorKV)(colors)

export const randomColorFromLoad = () =>
  pipe(
    entries,
    x => {
      const search = getSearch()
      if (colors[search.palette]) {
        let name = search.palette
        let current = merge({}, colors[name])
        if (search.f || search.flip || search.flipped) {
          name = `~` + name
          current = {
            fore: current.back,
            back: current.fore
          }
        }
        return [name, current]
      }
      return random.pick(x)
    },
    namedColorKV
  )(colors)

export const paletteColor = curry((lookup, def, x) =>
  pathOr(def, ["theme", "colors", lookup], x)
)
const paletteFore = paletteColor("fore")
const paletteBack = paletteColor("back")
export const fore = paletteFore("#fff")
export const back = paletteBack("#000")
