import qs from "qs"
import { replace, $ } from "f-utility"
import {
  concat,
  split,
  join,
  curry,
  map,
  ap,
  reduce,
  pathOr,
  pipe,
  toPairs,
  keys,
  mergeRight
} from "ramda"
import memo from "memoizee"
import { debounce } from "debounce"
import { PACKAGES } from "./constants"

export const isFunction = x => typeof x === "function"
export const isString = x => typeof x === "string"
export const { isArray } = Array
export const { freeze } = Object
export const isObject = x => typeof x === "object"

export const first = x => x && x.length && x[x.length - 1]
export const last = x => x && x.length && x[x.length - 1]

export const neue = x =>
  isArray(x) ? [].concat(x) : isObject(x) ? mergeRight({}, x) : x

// const coldpipe = (...args) => {
//   const x = pipe.apply(null, [neue, ...args, freeze])
//   const toString = a => a.toString() || `? => ?`
//   x.toString = () => `🍦 ( ${args.map(toString).join(` , `)} )`
//   return x
// }

export const omit = replace($, ``, $)
export const prepend = curry((x, y) => `${x}${y}`)

export const indexAny = curry((m, x) => x && x.indexOf && x.indexOf(m) > -1)
export const fuzzyMatcher = curry(function fuzzMatch(match, x, property) {
  const y = x && x[property]
  if (y && !isArray(y) && !isString(y)) {
    return keys(y).includes(match) || keys(y).filter(indexAny(match)).length > 0
  }
  return indexAny(match, y)
})
export const invokeFromProps = curry((method, props, x) =>
  props && props[method] && isFunction(props[method]) ? props[method](x) : x
)
export const box = x => [x]

export const grabAs = a =>
  pipe(pathOr(undefined, isArray(a) ? a : [a]), x => ({ [a]: x }))

export const keysAndFreeze = curry((keysToGrab, x) =>
  pipe(box, ap(map(grabAs, keysToGrab)), reduce(mergeRight, {}), freeze)(x)
)

export const grabLatestVersion = ({ versions }) =>
  pipe(
    keys,
    map(y =>
      pipe(
        split("."),
        map(Number),
        x => x.map((y, i) => Math.pow(y + 1, Math.abs(i - x.length))),
        join(""),
        Number,
        z => [y, z]
      )(y)
    ),
    last,
    y => y[0],
    z => mergeRight(versions[z], { version: z })
  )(versions)

export const quiet = omit(/!/g)

export const smoosh = curry((x, y) =>
  pipe(
    toPairs,
    reduce(
      (z, [k, v]) =>
        mergeRight(
          z,
          isArray(v) && z[k] ? { [k]: concat(z[k], v) } : { [k]: v }
        ),
      x
    )
  )(y)
)

export const pipeline = curry((cmd, input) =>
  pipe(
    toPairs,
    reduce(
      (x, [k, v]) =>
        k && v && x && x[k]
          ? mergeRight(x, {
              [k]: v(x[k])
            })
          : x,
      input
    )
  )(cmd)
)
export const stripCategory = omit(/CATEGORY:/g)

export const isPackage = indexAny($, PACKAGES)
export const reverse = x => {
  let out = ``
  for (let i = x.length - 1; i > -1; i--) {
    out += x[i]
  }
  return out
}
export const namedColorKV = ([name, { fore, back }]) => ({ name, fore, back })
export const slugify = memo(
  pipe(
    z => z.toLowerCase(),
    z => z.replace(/ /g, "-")
  )
)
export const leadingDebounce = curry((wait, x) => debounce(x, wait, true))

const qsparse = x =>
  map(z => (z === "true" ? true : z === "false" ? false : z), qs.parse(x))
export const getSearch = () =>
  window && window.location && window.location.search
    ? qsparse(window.location.search.substr(1))
    : {}

export const queryStringify = x =>
  window && window.location && window.location.search
    ? "?" +
      qs.stringify(mergeRight(qsparse(window.location.search.substr(1)), x))
    : ""

export const updateQS = (x, t = "brekk.is") =>
  window && window.history && window.history.replaceState
    ? window.history.replaceState(x, t, queryStringify(x))
    : null
