/** @jsx jsx */
/* @jsxFrag React.Fragment */
// eslint-disable-next-line
import React from "react"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { withThemeAndData, Button, jsx } from "../view"
import { updateQS } from "../utils"
import styles from "./styles"

const PlayPauseButton = ({ bem, isPlaying, setIsPlaying, theme }) => (
  <Button
    title="Play / Pause Color Change"
    onClick={() => {
      const now = !isPlaying
      setIsPlaying(now)
      updateQS({ playing: now })
    }}
    css={styles.button({ theme })}
  >
    <Icon icon={!isPlaying ? "play" : "stop"} />
  </Button>
)

export default withThemeAndData(PlayPauseButton)
