import { ReactComponent as Katsu } from "./logos/katsu-curry.svg"
import { ReactComponent as Entrust } from "./logos/entrust.svg"
import { ReactComponent as Handrail } from "./logos/handrail.svg"
import { ReactComponent as XTrace } from "./logos/xtrace.svg"
import { ReactComponent as LJS2 } from "./logos/ljs2.svg"
import { ReactComponent as FUtility } from "./logos/f-utility.svg"
import { ReactComponent as Blem } from "./logos/blem.svg"
import { ReactComponent as PhantomScript } from "./logos/phantomscript.svg"
import { ReactComponent as Gitparty } from "./logos/gitparty.svg"
import { ReactComponent as Snang } from "./logos/snang.svg"
import { ReactComponent as HalfBaked } from "./logos/half-baked.svg"
import { ReactComponent as Torpor } from './logos/torpor.svg'
import { ReactComponent as Bodypaint } from './logos/bodypaint.svg'
import { ReactComponent as Breakpoints } from './logos/breakpoints.svg'
import { ReactComponent as Brainwave } from './logos/brainwave.svg'

export const CATEGORIES = Object.freeze({
  NONE: `CATEGORY:none`
})

const cli = true
export const PACKAGES_AND_DESCRIPTIONS = {
  "katsu-curry": {
    description: "curry for your functions",
    component: Katsu
  },
  xtrace: { description: "side-effect / logging library", component: XTrace },
  entrust: {
    description: "tacit programming utility library",
    component: Entrust
  },
  blem: { description: "dead-simple BEM notation library", component: Blem },
  snang: {
    description: "transform stdin with tacit JS",
    component: Snang,
    cli
  },
  "f-utility": { description: "functional utilities", component: FUtility },
  bodypaint: {description: "the easiest responsive CSS-in-JS solution", component: Bodypaint },
  breakpoints: {description: "manage complex breakpoints with trivial effort", component: Breakpoints, owner: 'open-sorcerers' },
  torpor: {description: "manage the filesystem lazily from the future", component: Torpor },
  handrail: { description: "add safety with Eithers", component: Handrail },
  ljs2: {
    description: "literate programming library for js",
    component: LJS2,
    cli
  },
  phantomscript: {
    description: "zero-width character tool",
    component: PhantomScript,
    cli
  },
  gitparty: { description: "git log on steroids", component: Gitparty, cli },
  "half-baked": {description: "stupid simple local server based on express", component: HalfBaked},
  brainwave: {description: "orchestrate frontmatter content in your files", component: Brainwave }
}
export const PACKAGES = Object.keys(PACKAGES_AND_DESCRIPTIONS)
