import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
// import * as serviceWorker from "./serviceWorker"
import { createBrowserNavigation } from "navi"
import pages from "./pages"

const main = () => {
  const navigation = createBrowserNavigation({ pages })
  navigation
    .steady()
    .then(() => {
      ReactDOM.render(
        <App navigation={navigation} />,
        document.getElementById("root")
      )
    })
    .catch(e => console.warn(e))
}
main()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
