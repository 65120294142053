/** @jsx jsx */
/* @jsxFrag React.Fragment */
// eslint-disable-next-line
import React from "react"
import { jsx, blem, Box } from "./view"
import { pure, withState, withHandlers, lifecycle } from "recompose"
import { updateQS, getSearch, leadingDebounce, last } from "./utils"
import { pipe, propOr } from "ramda"
import { Global } from "@emotion/core"
import {
  NavProvider,
  NavContent,
  NavNotFoundBoundary,
  NavLoading
} from "react-navi"
import { ThemeProvider } from "emotion-theming"
import { DataContext } from "./data"

import Loading from "./LoadingBar"
import { randomColorFromLoad, randomColor } from "./styles"
import colors from "colors"
import error from "./RoutingError"

const ld500 = leadingDebounce(500)
const globalStyles = theme => `
html, body {
  scroll-behavior: smooth;
  background-color: ${theme.colors.back};
  color: ${theme.colors.fore};
  font-size: 16px;
  font-family: "Barlow Semi Condensed";
  margin: 0;
  padding: 0;
  text-align: center;
}
`
const App = ({
  navigation,
  bem = blem("App"),
  palette,
  nextColor,
  flipColors,
  packageManager,
  preferYarnOrNPM,
  previousSwatch,
  previousColor,
  isPlaying,
  setIsPlaying
}) => {
  const theme = {
    name: palette.name,
    colors: {
      fore: palette.fore,
      back: palette.back
    }
  }
  const data = {
    isPlaying,
    setIsPlaying,
    palette,
    nextColor,
    flipColors,
    packageManager,
    preferYarnOrNPM,
    previousSwatch,
    previousColor
  }
  return (
    <>
      <Global styles={globalStyles(theme)} />
      <DataContext.Provider value={data}>
        <ThemeProvider theme={theme}>
          <NavProvider navigation={navigation}>
            <NavLoading>
              {isLoading => (
                <Box>
                  <Loading isBusy={isLoading} />
                  <NavNotFoundBoundary render={error(theme)}>
                    <NavContent />
                  </NavNotFoundBoundary>
                </Box>
              )}
            </NavLoading>
          </NavProvider>
        </ThemeProvider>
      </DataContext.Provider>
    </>
  )
}

export const decorator = pipe(
  lifecycle({
    componentDidMount() {
      const runner = () => {
        if (!this.props.isPlaying) return
        const r = Math.random()
        const third = 1 / 3
        const twoThirds = 2 / 3
        if (this.props.previousSwatch.length > 0 && r > twoThirds) {
          this.props.previousColor()
          return
        }
        if (r >= third && r < twoThirds) {
          this.props.flipColors()
          return
        }
        this.props.nextColor()
      }
      let intervalId = setInterval(runner, 3e4)
      this.props.setColorInterval(intervalId)
    },
    componentWillUnmount() {
      clearInterval(this.props.colorID)
      this.props.setColorInterval(-1)
    }
  }),
  withState(
    "isPlaying",
    "setIsPlaying",
    pipe(getSearch, propOr(true, "playing"))
  ),
  withState("colorID", "setColorInterval", -1),
  withHandlers({
    preferYarnOrNPM: ({ useYarn, packageManager }) => () =>
      useYarn(!packageManager),
    flipColors: ({ palette, setSwatch }) =>
      ld500(() => {
        pipe(
          ({ name, fore, back }) => ({
            name: ~name.indexOf("~") ? name.replace("~", "") : `~${name}`,
            back: fore,
            fore: back
          }),
          x => {
            const flipped = x.name.includes("~")
            updateQS({ palette: flipped ? x.name.substr(1) : x.name, flipped })
            return x
          },
          setSwatch
        )(palette)
      }),
    previousColor: ({
      palette,
      setPreviousSwatch,
      previousSwatch,
      setSwatch,
      colors
    }) =>
      ld500(() => {
        const prev = last(previousSwatch)
        setSwatch(prev)
        previousSwatch.pop()
        updateQS({
          palette: prev.name,
          flipped: prev.name.includes("~")
        })
      }),
    nextColor: ({
      palette,
      colors,
      setSwatch,
      setPreviousSwatch,
      previousSwatch
    }) =>
      ld500(
        pipe(randomColor, newSwatch => {
          const x =
            previousSwatch.length > 19
              ? previousSwatch.slice(1)
              : previousSwatch
          setPreviousSwatch(x.concat(palette))
          setSwatch(newSwatch)
          updateQS({
            palette: newSwatch.name,
            flipped: newSwatch.name.includes("~")
          })
        })
      )
  }),
  withState("packageManager", "useYarn", true), // yarn === true
  withState("colors", "setColors", colors),
  withState("previousSwatch", "setPreviousSwatch", []),
  withState("palette", "setSwatch", randomColorFromLoad()),
  pure
)
export default decorator(App)
