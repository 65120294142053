/** @jsx jsx */
/* @jsxFrag React.Fragment */
// eslint-disable-next-line
import React from "react"
import { withThemeAndData, jsx, Button } from "../view"
import styles from "./styles"

const FlipColorsButton = ({ palette, flipColors, bem, override, theme }) => (
  <Button
    title="Flip Colors"
    className={bem("button", "flip")}
    css={
      override ? [styles.button({ theme }), override] : styles.button({ theme })
    }
    onClick={flipColors}
  >
    &#8651;
  </Button>
)

export default withThemeAndData(FlipColorsButton)
