/** @jsx jsx */
/* @jsxFrag React.Fragment */
import { Box, css, jsx } from "./view"
import { fore } from "./styles"
import { withTheme } from "emotion-theming"
// eslint-disable-next-line
import React from "react"

const mountains = (isBusy, theme) => {
  const fg = fore({ theme })
  return `
  padding-top: 20px;
  pointer-events: "none";
  background: linear-gradient(135deg, ${fg} 25%, transparent 25%) -1rem 0,
    linear-gradient(225deg, ${fg} 25%, transparent 25%) -2rem 0,
    linear-gradient(315deg, ${fg} 25%, transparent 25%),
    linear-gradient(45deg, ${fg} 25%, transparent 25%);
  position: fixed;
  height: 0.5rem;
  top: 0;
  left: 0;
  right: 0;
  background-size: 2em 2em;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
  transform: translateY(-4px);
  transition: transform ease-in 100ms, opacity ease-in 300ms;
  transition-delay: 0;
  @keyframes slide {
    0% {
      background-position: 0 4px;
    }
    100% {
      background-position: -4rem 4px;
    }
  }
  opacity: ${isBusy ? 1 : 0};
  animation: slide 1s cubic-bezier(0.4, 0.45, 0.6, 0.55) infinite;
  `
}

const Loading = ({ isBusy, theme }) => (
  <Box css={css(mountains(isBusy, theme))} />
)

export default withTheme(Loading)
