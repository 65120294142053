/** @jsx jsx */
/* @jsxFrag React.Fragment */
import { jsx, Flex } from "./view"
// eslint-disable-next-line
import React from "react"

import { pure } from "recompose"
import { pipe } from "ramda"

import FlipColorsButton from "./buttons/FlipColors"
import NextColorButton from "./buttons/NextColor"
import PlayPauseButton from "./buttons/PlayPause"
import PreviousColorButton from "./buttons/PreviousColor"

import "./icons"
// import { trace } from "xtrace"

const decorate = pipe(pure)

const ControlPanel = ({ bem }) => (
  <Flex
    className={bem("control-panel")}
    width={1}
    flexDirection="row"
    id="control-panel"
    alignItems="center"
    justifyContent="center"
  >
    <PreviousColorButton bem={bem} />
    <PlayPauseButton bem={bem} />
    <FlipColorsButton bem={bem} />
    <NextColorButton bem={bem} />
  </Flex>
)

export default decorate(ControlPanel)
